import React from 'react';
import { Link } from 'react-router-dom';
import homeVideo from '../../../assets/video/home.mp4'
import '../HomePage.css';
import '../../common/styles/main.css';
import { useTranslation } from 'react-i18next';

const VideoOverlay = () => {
    const { i18n, t } = useTranslation();
    return (
        <div className="video-container">
            <video className="background-video" autoPlay loop muted>
                <source src={homeVideo} type="video/mp4" />
                {t('ui_VideoError')}
            </video>
            <div className="overlay">
                <h1>{t("overlay_welcome_message")}</h1>
                <Link to='/addons'>{t("overlay_goToDownload")} <span translate='no' className="material-symbols-outlined">
                    play_arrow
                </span></Link>
            </div>
        </div>
    );
};

export default VideoOverlay;