import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"
import '../HomePage.css'
import '../../common/styles/main.css'
import Designer from './Designer.jpeg';

const News = () => {
    const { t } = useTranslation();
    return (
        <section className="sections" id='news'>
            <h2>{t('HomePage_MainContent_news')}</h2>
            <div>
                <article id='new_video'>
                    <h3>{t('HomePage_MainContent_news_newVideo')}</h3>
                    <div className='article-main'>
                        <iframe width="100%" height="100%"
                            src="https://www.youtube.com/embed/_NxGA9i8Lcc?si=kqdQVs4OiSZnD5d8"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen>
                        </iframe>
                    </div>
                </article>
                <article id='new_addon'>
                    <h3>{t('HomePage_MainContent_news_newAddon')}</h3>
                    <div className='article-main'>
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img src={Designer} alt='image' />
                        <Link to={'/addons/msdc'}>
                            <span translate='no' className='material-symbols-outlined'>
                                download
                            </span>
                        </Link>
                    </div>
                </article>
            </div>
        </section>
    )
};

export default News;