import React from 'react';
import '../AddonsPage.css';
import noImage from '../../../assets/image/MOWS_no_image.svg';
import failToloadImage from '../../../assets/image/MOWS_image_load_fail.svg';
// import PropTypes from 'prop-types';

const AddonButton = ({ image, name, description, author, downloads, updatedAt }) => {
    const imageUrl = image ? image : noImage;
    const handleImageError = (e) => {
        e.target.src = failToloadImage;
    }

    return (
        <div className="addon-button">
            <div id='main'>
                <img src={imageUrl} onError={handleImageError} alt='이미지 로딩 실패' />
                <h3>{name}</h3>
                <h4>{description}</h4>
            </div>
            <div id='meta'>
                <h5><span translate='no' className="material-symbols-outlined">account_circle</span>{author}</h5>
                <h5><span translate='no' className="material-symbols-outlined">download</span>{downloads}</h5>
                <h5><span translate='no' className="material-symbols-outlined">update</span>{new Date(updatedAt).toLocaleDateString()}</h5>
            </div>
        </div>
    );
};

// AddonButton.propTypes = {
//     image: PropTypes.string,
//     name: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     version: PropTypes.string.isRequired,
//     author: PropTypes.string.isRequired,
//     downloads: PropTypes.number.isRequired,
//     updatedAt: PropTypes.string.isRequired,
// };

export default AddonButton;
