import React from 'react';
import '../AddonDetailPage.css';

const AddonInfo = ({ addon }) => {
    return (
        <div className="addon-infos">
            <p>{addon.detail_info}</p>
            <p>Version: {addon.version}</p>
            <p>Author: {addon.author}</p>
            <p id='download_count'>Downloads: {addon.download_count}</p>
            <p>Last Updated: {addon.update_date}</p>
        </div>
    );
};

export default AddonInfo;
