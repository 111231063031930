import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NoServerResponsePage from '../error/no server response'
import NotFoundPage from '../error/404 not found';
import PageTitle from './components/PageTitle';
import './AddonDetailPage.css';
import '../common/styles/main.css'
import { fetchAddons, isExisting } from '../../api';  // ID로 애드온 데이터를 가져오는 API 호출 함수
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import AddonImages from './components/AddonImages';
import AddonInfo from './components/AddonInfo';


const AddonDetailPage = () => {
    const { t } = useTranslation()
    const { code_name } = useParams();
    const [addon, setAddon] = useState(null);
    const [lastDownloadTime, setLastDownloadTime] = useState(0)

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            event.preventDefault();
            const currentTime = Date.now();
            if (currentTime - lastDownloadTime < 2000) {
                alert(`${(2000 - currentTime + lastDownloadTime) / 1000}초 후 다시 시도하세요`)
                return;
            }
            setLastDownloadTime(currentTime);
            const response = await axios.post('http://192.168.219.134:8000/api/addons/download/', { code_name: code_name }, {
                responseType: 'blob',
            });
            console.log(response['headers'])
            const contentDisposition = response.headers['content-disposition'];
            console.log(contentDisposition)
            let filename = addon.name; // Default name
            if (contentDisposition) {
                let filenameMatch = contentDisposition.match(/filename\*?=([^;]+)/i);
                if (filenameMatch) {
                    let filenamePart = filenameMatch[1].trim();

                    if (filenamePart.startsWith("UTF-8''")) {
                        filename = decodeURIComponent(filenamePart.replace("UTF-8''", ''));
                    } else {
                        // Remove potential quotes around filename
                        filename = filenamePart.replace(/(^")|("$)/g, '');
                    }
                }
            }

            // 파일 다운로드
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // 파일 이름 지정
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const download_count = document.getElementById('download_count')
            download_count.innerHTML = download_count.innerHTML.split(' ')[0] + ' ' + (parseInt(download_count.innerHTML.split(' ')[1]) + 1)
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    console.log(code_name)

    useEffect(() => {
        const checkExistenceAndFetch = async () => {
            const exist = await isExisting(code_name); // await 사용
            console.log(exist);
            if (exist === 1) {  // 비동기 작업이 끝난 후의 값 확인
                const res = await fetchAddons(code_name);  // fetchAddons도 비동기 함수라면 await 사용
                setAddon(res);
            }
            else if (exist === 0) {
                setAddon('No-Such-Addon')
            }
            else {
                setAddon('No-Server-Response')
            }
        };
        checkExistenceAndFetch(); // 비동기 함수를 호출
    }, [code_name]);

    if (!addon) {
        return <div>Loading...</div>;  // 데이터를 불러오는 중일 때 표시할 내용
    }
    else if (addon === 'No-Such-Addon') {
        return (
            <NotFoundPage reason={'No Such Addon'} />
        )
    }
    else if (addon === 'No-Server-Response') {
        return (
            <NoServerResponsePage />
        )
    }
    else {
        return (
            <main className="addon-detail-page">
                <PageTitle addon_name={t(addon.name)} addon_img={null} />
                <AddonImages code_name={code_name} />
                <AddonInfo addon={addon} />
                <form onSubmit={handleSubmit}>
                    <button type="submit" id='download_button'>다운로드</button>
                </form>
            </main>
        );
    }
};

export default AddonDetailPage;