import React from 'react';
import { Link } from 'react-router-dom';
// import './NotFoundPage.css';

const NoServerResponsePage = () => {
    return (
        <div className="no-server-response-page">
            <h1><span translate='no' className="material-symbols-outlined"> database_off </span>서버 응답 없음. No Server Response.</h1>
            <h2>나중에 다시 시도하세요. Try again later.</h2>
            <p>다음이 문제일 수 있습니다. The following may be your problem.</p>
            <ol>
                <li>네트워크 연결 없음. No network connection.</li>
                <li>지원하지 않는 국가에 거주중. Living in a country that is not supported.</li>
                <li>서버 점검중. Server Maintanance</li>
            </ol>
            <Link to="/">Go to Home</Link>
        </div>
    );
};

export default NoServerResponsePage;