import React from 'react';
import VideoOverlay from './components/VideoOverlay';
import MainContent from './components/MainContent';
import './HomePage.css';

const HomePage = () => {
    return (
        <div className="home-page">
            <div className='main-viewport'>
                <VideoOverlay />
                <MainContent />
            </div>
        </div>
    );
};

export default HomePage;
