import React from 'react';
import '../AddonsPage.css'; // Skeleton 스타일링을 위한 CSS 파일

const Skeleton = () => {
    return (
        <div className="skeleton-addon">
            <div className="skeleton-thumbnail"></div>
            <div className="skeleton-title"></div>
            <div className="skeleton-meta"></div>
        </div>
    );
};

export default Skeleton;