import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ko_KR from './ko_KR.json';
import en_uS from './en_US.json';

export default i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'ko_KR',
        debug: true,
        detection: { order: ["path", "navigator"] },
        resources: {
            ko_KR: {
                translation: ko_KR,
            },
            en_US: {
                translation: en_uS,
            },
        },
    });
