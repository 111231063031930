import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchAddons } from '../../../api';
import { useTranslation } from "react-i18next"
import '../HomePage.css'
import '../../common/styles/main.css'

const MajorAddon = () => {
    const { t } = useTranslation();
    const [addons, setAddons] = useState(null);
    const [filteredAddons, setFilteredAddons] = useState([]);

    useEffect(() => {
        fetchAddons().then(data => {
            if (data !== -1) {
                setAddons(data);
                setFilteredAddons(data);
            }
        });
    }, []);

    useEffect(() => {
        if (addons) {
            let sortedAddons = [...addons];
            sortedAddons.sort((a, b) => new Date(b.update_date) - new Date(a.update_date));
            setFilteredAddons(sortedAddons);
        }
    }, [addons])

    return (
        <section className="sections" id='addons'>
            <h2>{t('HomePage_MainContent_majoraddons')}</h2>
            <div>
                {filteredAddons.map(i => (
                    <Link to={`addons/${i.code_name}`}>
                        <img src={i.addon_icon} alt={'null'} />
                    </Link>
                ))}
            </div>
        </section>
    )
};

export default MajorAddon;