import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from './components/PageTitle';
import SortMenu from './components/SortMenu';
import AddonButton from './components/AddonButton';
import Skeleton from './components/SkeletonButton';
import './AddonsPage.css';
import { fetchAddons } from '../../api';

const AddonsPage = () => {
    const [addons, setAddons] = useState(null);
    const [filteredAddons, setFilteredAddons] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [sortCriterion, setSortCriterion] = useState('name');

    useEffect(() => {
        fetchAddons().then(data => {
            if (data !== -1) {
                setAddons(data);
                setFilteredAddons(data);
            }
        });
    }, []);

    useEffect(() => {
        if (addons) {
            let sortedAddons = [...addons];
            if (sortCriterion === 'name') {
                sortedAddons.sort((a, b) => a.name.localeCompare(b.name));
            } else if (sortCriterion === 'downloads') {
                sortedAddons.sort((a, b) => b.download_count - a.download_count);
            } else if (sortCriterion === 'update_date') {
                sortedAddons.sort((a, b) => new Date(b.update_date) - new Date(a.update_date));
            }

            if (selectedCategories.length > 0) {
                sortedAddons = sortedAddons.filter(addon =>
                    selectedCategories.includes(addon.category)
                );
            }
            setFilteredAddons(sortedAddons);
        }
    }, [sortCriterion, selectedCategories, addons]);

    const handleSortChange = (criterion) => {
        setSortCriterion(criterion);
    };

    const handleCategoryChange = (categories) => {
        setSelectedCategories(categories);
    };

    return (
        <main className="addons-page">
            {/* <Nav /> */}
            <div className='main_content'>
                <PageTitle title={'Download'} />
                {!addons ? (
                    <section>
                        <div className='skeleton-sort' />
                        <div className="addons-list">
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </section>
                ) : (
                    <section>
                        <SortMenu onSortChange={handleSortChange} onCategoryChange={handleCategoryChange} />
                        <div className="addons-list">
                            {filteredAddons.map(i => (
                                <Link key={i.code_name} className="addon-link" to={`/addons/${i.code_name}`} title={i.name + ' 다운로드'}>
                                    <AddonButton
                                        image={i.addon_icon}
                                        name={i.name}
                                        description={i.detail_info}
                                        version={i.version}
                                        author={i.author}
                                        downloads={i.download_count}
                                        updatedAt={i.update_date}
                                    />
                                </Link>
                            ))}
                        </div>
                    </section>
                )}
            </div>
        </main >
    );
};

export default AddonsPage;
