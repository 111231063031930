import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import HomePage from './pages/home/HomePage';
import AddonsPage from './pages/addons/AddonsPage';
import AddonDetailPage from './pages/addonDetail/AddonDetailPage';
import NotFoundPage from './pages/error/404 not found';
import './index.css'
import './pages/common/styles/main.css'
import Layout from './pages/common/components/Layout';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />} >
          <Route index element={<HomePage />} />
          <Route path='addons/:code_name' element={<AddonDetailPage />} />
          <Route path='addons' element={<AddonsPage />} />
        </Route>
        <Route path='/docs'>
          <Route index element={<NotFoundPage />} />
          <Route path=':doc_name' element={<NotFoundPage />} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;