import React from "react";
import '../../common/styles/main.css'

const PageTitle = ({ addon_name, addon_img }) => {
    return (
        <header>
            <h1 id="page_title">{addon_name}</h1>
            <img src={addon_img} alt="null" />
        </header>
    )
}

export default PageTitle;