import axios from 'axios';

const ip = 'localhost:8000';

export const fetchAddons = async (code_name = '') => {
    try {
        const response = await axios.get(`http://${ip}/api/addons/${code_name}`);
        return response.data;
    } catch (error) {
        if (!error) {
            console.log('server off')
            return -1;
        }
        if (code_name !== '') {
            console.log('model not found')
            return 404;
        }
        console.error('Failed to fetch addons:', error);
        return -1;
    }
};

export const isExisting = async (code_name) => {
    try {
        const response = await axios.get(`http://${ip}/api/addons/isexist/${code_name}`);
        return response.data['response'];
    }
    catch {
        return -1;
    }
}

export const fetchAddonImages = async (code_name) => {
    console.log('trying to fetch image')
    try {
        const response = await axios.get(`http://${ip}/api/addons/images/${code_name}`);
        console.log('response : ' + response)
        return response.data;
    }
    catch {
        return -1;
    }
}