import React from "react";
import { useTranslation } from "react-i18next"
import '../HomePage.css'
import '../../common/styles/main.css'

const SocialLink = () => {
    const { t } = useTranslation();
    return (<section className="sections" id='social'>
        <h2>{t('HomePage_MainContent_sociallink')}</h2>
        <p>Social media links...</p>
    </section>)
}

export default SocialLink;