import React from 'react';
import '../HomePage.css';
import SocialLink from './SocialLinks';
import News from './News';
import MajorAddon from './MajorAddon';
import HomeMenu from './HomeMenu';

const MainContent = () => {
    return (
        <div className="main-content">
            <HomeMenu />
            <MajorAddon />
            <News />
            <SocialLink />
        </div>
    );
};

export default MainContent;